<template>
  <v-btn color="info" outlined @click="accessSignMode">
    Proceso de firmas Inicial
    <v-icon class="ml-2" size="18">mdi-lock</v-icon>
  </v-btn>
</template>

<script>
export default {
  methods: {
    async accessSignMode() {
      const confirmed = await this.$confirm(
        "¿Quieres entrar en modo firma? Podrás dar el dispositivos a los alumnos para que empiezen el proceso de firma.",
        {
          title: "Modo firma",
          color: "info",
        }
      );

      if (confirmed) {
        await this.$router.push({
          name: "trainerSessionInitialSignatures",
          params: { id: this.$route.params.id },
        });
      }
    },
  },
};
</script>

<style scoped></style>
